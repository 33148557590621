<div
	class="actfirst-bg margin-right">
	<a
		class="actfirst-logo"
		[routerLink]="['/', site['customerid'], site['id'], 'act-first', 'private']"
		></a>
		<div *ngIf="!pageConnectivity" class="row">
			<div class="col-lg-6 col-md-push-3 alert alert-danger text-center"><strong>{{'act.page_is_offline' | translate}}</strong></div>
		</div>
	<div
		[ngClass]="{'glow': buzzing}" class="actfirst-content">
		<iframe src="/audio/silence.mp3" allow="autoplay" id="audio" style="display:none"></iframe>
		<div
			class="no-gutter group-box"
			[ngClass]="groupKey['is_full_row'] ? 'col-xs-12' : 'col-xs-6' "
			*ngFor="let groupKey of dataSorted">
			<div
				class="groupname"><img src="/images/act_first/water_icon.png" alt="Water Icon" class="groupname-icon" *ngIf="processingObj.showWaterIcon">{{groupKey['groupname']}}<div><span>{{ getCurrentTime() }}</span></div></div>
			<div
				class="groupname">{{groupKey['groupname']}}</div>
			<div
				class="ready-charger-box"
				*ngFor="let item of groupKey['innergroups']['ready']">
				<div class="col-sm-6 text-right left-container">
					<div class="ready-charger-img">
						<span [ngClass]="groupKey['is_full_row'] ? 'over-ready-img-12' : 'over-ready-img-6'">{{item.currentSOC}}<span style="font-size: 24px; font-weight: 200">%</span></span>
					</div>
				</div>
				<div class="col-sm-6 right-container">
					<div
						class="row"
						style="margin-top:40px">
						<div
							class="no-gutter-left light">{{'act.location' | translate}}</div>
						<div
							class="no-gutter-left ready-charger">{{item.name}}</div>
						<div
							class="unplug-container pointer"
							*ngIf="!item.connected"
							(click)="unplugReadyCharger(item)">
							<button class="btn btn-primary">
								<div class="unplug-img"></div>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				*ngIf="groupKey['innergroups']['ready'].length == 0" class="ready-charger-box" style="text-align: center">
				<h3>{{'g.none' | translate}}</h3></div>
		</div>
		<div class="margin-top" *ngIf="site['pm']">
			<button
				class="btn btn-default btn-lg"
				(click)="pmpassword.show()">PM</button>
		</div>
	</div>
</div>
<app-modal
	#pmpassword
	[title]="'act_first.pm_password'| translate"
	[hasConfirmButton]="false"
	[hasCloseButton]="false">
  <div class="margin-bottom-sm">{{'act_first.enter_pm_pass' | translate}}:</div>
	<input
		type="number"
		class="form-control"
		name="pm_password"
		[(ngModel)]="password"/>
  <div class="padding-8">
    <button
    	class="btn btn-primary"
    	title="{{'act_first.pm_password' | translate}}"
    	[disabled]="!password"
    	(click)="pmpassword.hide();ignorePMMispick()">{{'g.submit' | translate}}</button>
    <button class="btn btn-default" (click)="pmpassword.hide();password=null;">{{'g.close' | translate}}</button>
  </div>
</app-modal>
